import request from "@/api/service";
import qs from 'qs'
const api = {
  // 首页基础数据
  getGroupData: '/hadoop/company/getGroupData',
  // 集团客房经营详情
  getCrsOrderCount: '/order/getCrsOrderCount'
}

// 集团客房经营
export function getGroupData(params) {
  return request({
     url: api.getGroupData,
     method: 'post',
    //  header:{
    //    contentType:'application/x-www-from-urlencode',
    //  },
    //  contentType:'application/x-www-from-urlencode',
     data:qs.stringify(params)
  })
}

export function getCrsOrderCount(params){
  return request({
    url:api.getCrsOrderCount,
    method:'post',
    data:params
  })
}