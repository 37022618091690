<!-- 集团管理 首页 -->
<template>
  <section class="cont group_index">
    <el-row class="crumbs-box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>集团管理</el-breadcrumb-item>
        <el-breadcrumb-item>首页</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <el-row class="content-box">
      <el-row class="card-box" type="flex">
        <div class="card-box-item">
          <p class="item-tit">门店数量</p>
          <p class="item-text">{{ groupSaleData.hotelCount || 0 }}</p>
        </div>
        <div class="card-box-item" style="margin: 0 200px">
          <p class="item-tit">集团会员数</p>
          <p class="item-text">{{ groupSaleData.userCount || 0 }}</p>
        </div>
        <div class="card-box-item">
          <div>
            <p class="item-tit">集团积累销售额</p>
            <p class="item-text">{{ groupSaleData.totalMoney | MoneyFilter }}</p>
          </div>
        </div>
      </el-row>

      <el-row class="card-box m-top-60" type="flex">
        <div class="card-box-item">
          <p class="item-tit">新增销售额</p>
          <p class="item-text">{{ groupNewSaleData.actualAmount | MoneyFilter }}</p>
        </div>
        <div class="card-box-item" style="margin: 0 200px">
          <p class="item-tit">新增订单数</p>
          <p class="item-text">{{ groupNewSaleData.orderCount || 0 }}</p>
        </div>
        <div class="card-box-item">
          <div>
            <p class="item-tit">新增会员数</p>
            <p class="item-text">{{ groupNewSaleData.userNum || 0 }}</p>
          </div>
        </div>
      </el-row>

      <el-row class="search-box m-top-60">
        <el-row class="search-row" style="margin-bottom:0">
          <el-row class="search-item">
            <el-radio-group v-model="checkboxGroup" @change="radioChange">
              <el-radio-button
                v-for="(state, idx) in statisticsStateList"
                :key="idx"
                :label="state.value"
                :value="state.value"
              > {{state.label}}</el-radio-button>
            </el-radio-group>
          </el-row>
          <el-row class="search-item">
            <el-date-picker
              v-show="checkboxGroup === 'customDate'"
              :disabled="checkboxGroup !== 'customDate'"
              ref="datePicker"
              value-format="yyyy-MM-dd"
              class="date-ipt"
              v-model="dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-row>
          <el-button type="primary" @click="resetFiltrate">重置</el-button>
          <el-button v-show="checkboxGroup === 'customDate'" type="primary" class="m-left-auto" @click="search" :disabled="checkboxGroup !== 'customDate'">搜索</el-button>
        </el-row>
      </el-row>

      <el-row  style="padding: 40px 0px">
        <el-row
          id="Discount_chart"
          style="width: 92%; height: 500px; margin-top: 1rem; "
        >
        </el-row>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import { reconciliation } from "@/api/interface/business";
import { dateFactory } from "@/common/js/common";
import { getGroupData, getCrsOrderCount } from "@/api/GroupManagement/groupIndex";
import { mapState } from "vuex";
export default {
  data() {
    return {
      checkboxGroup: "today",
      statisticsStateList: [
        { label: "本日", value: 'today' },
        { label: "昨日", value:'yesterday' },
        { label: "本周", value: 'week' },
        { label: "本月", value: 'month' },
        { label: "自定义", value: 'customDate' },
      ],
      dateRange: [],
      groupSaleData: { // 集团总数据
        totalMoney: 0,
        userCount: 0,
        hotelCount: 0,
      },
      groupNewSaleData:{} // 集团新增数据
    };
  },
  mounted() {
    this.today = dateFactory.getDistanceToday(0,false)
    this.getWeekList = dateFactory.getWeekList()
    this.dateRange = [this.today,this.today]
    this.getGroupData(); // 获取集团总数据
    // this.getgroupAccount();
    this.getCrsOrderCount(); // 获取集团新增数据
    this.chartEvent() // 获取报表数据
  },
  methods: {
    getCrsOrderCount(){ // 获取订单数据
      let params = {
        beginTime:this.dateRange[0],
        endTime :this.dateRange[1],
        storeId:this.hotelInfo.storeId
      }
      getCrsOrderCount(params).then( res => {
        if(res.success){
          this.groupNewSaleData = res.records
        }
      })
    },
    getGroupData() {
      let params = {
        companyId: this.hotelInfo.storeId,
      };
      getGroupData(params).then((res) => {
        if (res.success) {
          this.groupSaleData = res.records;
        }
      });
    },

    // 单选框事件
    radioChange() {
      switch (this.checkboxGroup) {
        case "today":
          this.dateRange = [this.today, this.today];
          break;
        case "yesterday":
          this.dateRange = [dateFactory.getDistanceToday(-1,false), dateFactory.getDistanceToday(-1,false)]
          break;
        case "week":
          this.dateRange = [this.getWeekList[0], this.getWeekList[6]];
          break;
        case "month":
          this.dateRange = this.getMonth();
          break;
      }
      if(this.checkboxGroup !== 'customDate'){
        // this.getGroupData();
        // this.getgroupAccount();
        this.getCrsOrderCount()
        this.chartEvent()
      }
    },
    //获取本月开始日期+结束日期
    getMonth(){
      let arr = [dateFactory.getMonthDate().split(" ")[0],dateFactory.getMonthDate('end').split(" ")[0]]
      return arr;
    },
    resetFiltrate() {
      // 重置筛选
      this.dateRange = [this.today,this.today]
      this.checkboxGroup = 'today'
    },
    search() {
      // 搜索
      this.getCrsOrderCount()
      this.chartEvent()
    },
    //获取/设置echarts数据
    chartEvent() {
      let chartDom = document.getElementById("Discount_chart");
      let myChart = this.$echarts.init(chartDom);
      //延时0.5秒重绘解决初始化宽度问题
      setTimeout(()=>{
          myChart.resize();
      },500)
      let Discount_chart;
      const url = reconciliation.getAmountDetail;
      let param = { storeId:this.hotelInfo.storeId };
      //本日昨日 date参数
      if ( this.checkboxGroup === "today" || this.checkboxGroup === "yesterday") {
        param.date = this.dateRange[0];
        param.oneDay = true;
      } else {
        if(this.checkboxGroup === "customDate"&&this.dateRange[0]===this.dateRange[1]){
          param.date = this.dateRange[0];
        param.oneDay = true;
        }else{
        param.beginTime = this.dateRange[0];
        param.endTime = this.dateRange[1];
        }
      }
      this.$axios.get(url, param).then((res) => {
        if (res.success) {
          let chartData = res.records;
          this.cha_totalMoney = [];
          this.cha_hotelMoney = [];
          this.cha_otherMoney = [];
          this.timeData = [];
          let hoursNum = ""; //设置echarts X轴时间段

          switch (this.checkboxGroup) {
            case "today":
              hoursNum = dateFactory
                .getDistanceToday(0, true)
                .substring(11, 13);
              break;
            case "yesterday":
              hoursNum = 23
              break;
            case "week":
              this.timeData = this.getWeekList;
              hoursNum = 7;
              break;
            case "month":
              hoursNum = Number(this.today.substring(8, 11));
              break;
            case "customDate":
              if(this.dateRange[0] ===this.dateRange[1]){
                hoursNum = 23
              }else{
                this.timeData = dateFactory.getDateRange(
                this.dateRange[0],
                this.dateRange[1]
              );
              hoursNum = this.timeData.length;
              }
              break;
          }
          for (let i = 0; i <= hoursNum; i++) {
            if ( this.checkboxGroup === "today" || this.checkboxGroup === 'yesterday' ||(this.checkboxGroup === "customDate"&&this.dateRange[0]===this.dateRange[1]) )
              this.timeData.push(i + "时");
            if (this.checkboxGroup === "month")
            this.timeData.push(i < 10 ? "0" + i + "日" : i + "日");
            this.cha_totalMoney.push(0);
            this.cha_hotelMoney.push(0);
            this.cha_otherMoney.push(0);
          }
          chartData.forEach((item) => {
            //判断获取对应下标
            if ( this.checkboxGroup === "today" || this.checkboxGroup === "yesterday" || (this.checkboxGroup === "customDate"&&this.dateRange[0]===this.dateRange[1]) ) {
              if (item.date) {
                if (item.date.indexOf("0") == 0)
                  item.dayIndex = item.date.substring(1, 2);
                else item.dayIndex = item.date;
              }
            } else if (
              this.checkboxGroup === "week" ||
              (this.checkboxGroup === "customDate" && this.dateRange[0]!==this.dateRange[1])
            ) {
              this.timeData.forEach((items, indexs) => {
                if (items == item.date) item.dayIndex = indexs;
              });
            } else if (this.checkboxGroup === "month") {
              this.timeData.forEach((items, indexs) => {
                if (items.substring(0, 2) === item.date.substring(8, 10))
                  item.dayIndex = indexs;
              });
            }
            if (item.totalMoney && Number(item.dayIndex) <= Number(hoursNum))
              this.cha_totalMoney[item.dayIndex] = item.totalMoney;
            if (item.hotelMoney && Number(item.dayIndex) <= Number(hoursNum))
              this.cha_hotelMoney[item.dayIndex] = item.hotelMoney;
            if (item.otherMoney && Number(item.dayIndex) <= Number(hoursNum))
              this.cha_otherMoney[item.dayIndex] = item.otherMoney;
          });
          //总销售额  房费销售额 非房费销售额
          this.seriesData = [
            { name: "总销售额", type: "line", data: this.cha_totalMoney, smooth:true,
              areaStyle: {
                color:{
                  colorStops:[
                    { offset: 0, color: "rgba(194,53,49,0.5)" },
                    { offset: 0.5, color: "rgba(194,53,49,0.3)" },
                    { offset: 1, color: "rgba(194,53,49,0.1)" }
                  ],
                }
              }
            },
            { name: "房费销售额", type: "line", data: this.cha_hotelMoney,smooth:true,
              areaStyle: {
                color:{
                  colorStops:[
                    { offset: 0, color: "rgba(47,69,84,0.5)" },
                    { offset: 0.5, color: "rgba(47,69,84,0.3)" },
                    { offset: 1, color: "rgba(47,69,84,0.1)" }
                  ],
                }
              }
            },
            { name: "非房费销售额", type: "line", data: this.cha_otherMoney,smooth:true,
              areaStyle: {
                color:{
                  colorStops:[
                    { offset: 0, color: "rgba(97,160,168,0.5)" },
                    { offset: 0.5, color: "rgba(97,160,168,0.3)" },
                    { offset: 1, color: "rgba(97,160,168,0.1)" }
                  ],
                }
              }
            },
          ];

          let legendData = [];
          this.seriesData.forEach((item) => {
            legendData.push(item.name);
          });
          // 折线图
          Discount_chart = {
            title: {
              text: "收入",
              // subtext: '订单数',
            },
            tooltip: {
              trigger: "axis",
              show: true,
            },
            grid: {
              left: "0%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            data: ["订单数1"],
            legend: {
              data: legendData,
            },
            xAxis: {
              type: "category",
              data: this.timeData,
            },
            yAxis: {
              type: "value",
            },
            series: this.seriesData,
          };
          Discount_chart && myChart.setOption(Discount_chart);
          window.onresize = () => {
            myChart.resize();
          };
        }
      });
    },
    //获取销售额数据
    getgroupAccount() {
      const url = reconciliation.getAmount;
      let param = {
        storeId: this.hotelInfo.storeId,
        beginTime: this.dateRange[0],
        endTime: this.dateRange[1],
      };
      this.$axios.post(url, param, "json").then((res) => {
        if (res.success) {
          if (res.hasOwnProperty("records")) {
            this.groupsale.totalMoney = res.records.totalMoney;
            this.groupsale.hotelMoney = res.records.hotelMoney;
            this.groupsale.otherMoney = res.records.otherMoney;
          } else {
            this.groupsale.totalMoney = 0;
            this.groupsale.hotelMoney = 0;
            this.groupsale.otherMoney = 0;
          }
        }
      });
    },
  },
  computed: {
    ...mapState(["hotelInfo"]),
  },
  filters:{
    MoneyFilter(value){
      if(!value) return '0.00';	
      var intPart =  Number(value)|0; //获取整数部分
      var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分每三位加一个逗号
      var floatPart = ".00"; //预定义小数部分
      if(typeof value !== 'string'){
        value = value.toString();
      }
      var value2Array = value.split(".");
    
      //=2表示数据有小数位
      if(value2Array.length == 2) {
        floatPart = value2Array[1].toString(); //拿到小数部分
        if(floatPart.length > 2 ) { //若小数位数在两位以上，则四舍五入保留两位小数
          return parseFloat(value).toFixed(2);
        } else if(floatPart.length == 1) { //若小数位数只有一位，补0
          return intPartFormat + "." + floatPart + '0';
        } else {
          return intPartFormat + "." + floatPart;
        }
    
      } else {  //数据为整数
        return intPartFormat + floatPart;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-box {
  $gradient-blue: linear-gradient(90deg, #006fff, #189aff);
  $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
  $gradient-org: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
  $gradient-yellow: linear-gradient(
    90deg,
    rgb(255, 161, 50),
    rgb(255, 209, 50)
  );
  $gradient-red: linear-gradient(90deg, rgb(163, 50, 255), rgb(151, 99, 233));
  $list-bg: $gradient-blue, $gradient-green, $gradient-org, $gradient-yellow,
    $gradient-red;
  .card-box-item {
    width: 20%;
    padding: 10px 40px;
    background-repeat: no-repeat;
    text-align: center;
    background-position: 0 0;
    background-size: 100% 100%;
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 6px;
    @each $c in $list-bg {
      $i: index($list-bg, $c);
      &:nth-child(#{$i}) {
        background: nth($list-bg, $i);
      }
    }
    .item-tit {
      text-align: left;
      font-size: 18px;
      font-weight: 400;
    }
    .item-text {
      margin:30px 0;
      font-size: 30px;
      font-weight: 700;
    }
    .item-rect {
      background: #f1f1f1;
      width: 10px;
      height: 18px;
      margin-right: 3px;
    }
    .item-rect-ac {
      background: #ff2332;
    }
  }
}
</style>